import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/content"
import PageIntro from "../components/pageIntro"
import MenuReset from "../components/MenuReset"

export const ServiceLayout = ({ content, title, contentIsMarkdown, image }) => (
  <>
    <PageIntro>
      <h2>{title}</h2>
    </PageIntro>
    <Content>
      <div className="row">
        <div className="col">
          <div className="row">
            <div className="content">
              {typeof content === "object" && (
                <pre>{JSON.stringify(content, null, 2)}</pre>
              )}
              {contentIsMarkdown ? (
                <ReactMarkdown source={content} />
              ) : (
                <div dangerouslySetInnerHTML={{ __html: content }} />
              )}
            </div>
          </div>
        </div>
        {image && (
          <div className="col">
            <img src={image} alt={title} />
          </div>
        )}
      </div>
    </Content>
  </>
)

export default function Service({ data }) {
  const post = data.markdownRemark
  return (
    <Layout solidHeader>
      <SEO title={post.frontmatter.title} />
      <MenuReset />

      <ServiceLayout
        title={post.frontmatter.title}
        content={post.html}
        tags={post.frontmatter.tags}
        image={post.frontmatter.image}
      />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        image
      }
    }
  }
`
